<!--
 * @Descripttion:
 * @version:
 * @Author: 李宗哲
 * @Date: 2020-03-09 17:12:51
 * @LastEditors: 霍铁樱
 * @LastEditTime: 2021-01-06 22:26:50
 -->
 <template>
  <div>
    <div class="content">
      <div class="information">
         <div class="table" v-if="dataList.length>0">
          <div class="ziliao"
               v-for="(item,index) in dataList"
               :key="index"
               @click="cpinformation(item)" >
            <div class="lxtupian">
              <img :src="item.img"
                   alt=""
                   class="mainpic">
            </div>
            <p class="ziliaoname">{{item.title}}</p>
          </div>
        </div>
        <div class="table" v-else>
             <div style="color:#999999">当前暂无产品资料</div>
        </div>
        <!-- <div class=" table">
          <div class="ziliao"
               v-for="(item1,index1) in Lists"
               :key="index1"
               @click="cpinformation(item1)">
            <div class="lxtupian">
              <img :src="item1.img"
                   alt=""
                   class="mainpic">
            </div>
            <p class="ziliaoname">{{item1.title}}</p>
          </div>
        </div> -->
      </div>
    </div>
    <md-landscape v-model="showPic">
      <div class="showsctp"></div>
    </md-landscape>
  </div>
</template>

<script>
import { selecschb, selectcpzl, getInsureUrl } from '@/api/agent/agent/index'
import { getStorage } from '@/lib/util'
import { Toast } from 'mand-mobile'
export default {
  components: {

  },
  data () {
    return {
      prodcode: '',
      ossurl: '',
      iconhttp: '',
      dataList: [],
      list: [
        {
          img: require('@/assets/nh/nhwx-101.png'),
          title: '费率表',
          code: 'FLB'
        },
        {
          img: require('@/assets/nh/nhwx-102.png'),
          title: '健康告知',
          code: 'JKGZ'
        },
        {
          img: require('@/assets/nh/nhwx-103.png'),
          title: '投保须知',
          code: 'TBXZ'
        },
        {
          img: require('@/assets/nh/nhwx-104.png'),
          title: '职业类别',
          code: 'ZYFL'
        },
        {
          img: require('@/assets/nh/nhwx-105.png'),
          title: '常见问题',
          code: 'CJWT'
        },
        {
          img: require('@/assets/nh/nhwx-106.png'),
          title: '附加条款',
          code: 'FJFW'
        },
        {
          img: require('@/assets/nh/nhwx-107.png'),
          title: '保全理赔',
          code: 'BQLP'
        },
        {
          img: require('@/assets/nh/nhwx-108.png'),
          title: '产品条款',
          code: 'CPTK'
        },
        {
          img: require('@/assets/nh/nhwx-109.png'),
          title: '产品介绍',
          code: 'CPJS'
        }
      ],
      // Lists: [
      // ],
      // sctp: '',
      showPic: false,
      // ziliao: [],
      prodcp: '',
      showfile: []

    }
  },
  created () {
    this.ossurl = getStorage('ossurl', '')
    this.u_s = getStorage('u_s', '')
    this.prodcode = this.$route.query.prodcode
    this.information()
  },
  methods: {
    information () {
      selectcpzl({ prodcode: this.prodcode, qdbk: '1' }).then(res => {
        console.log(res);
        let arr = res.data.data.showfile
        this.prodcp = res.data.data.showmsg
        this.list.forEach((item, index) => {
          arr.forEach((item1, index1) => {
            if (item.code == item1.code) {
              this.dataList.push(item)
            }
          })
        })
      })
    },
    //产品资料
    cpinformation (val) {
      this.$router.push(
        `/pdfinformation?prodcode=${this.prodcode}&code=${val.code}`
      )
    }
  }
}
</script>

 <style scoped>
.content {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.showImg {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 2.5rem;
  border-radius: 0.2rem;
  position: relative;
  height: 3rem;
}
.showLeft {
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 0.5rem 0 0.5rem;
  height: 4.5rem;
}
.showTitle {
  width: 65%;
}
.cpname {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.45rem;
  margin-top: 0.3rem;
  color: #383838;
  font-family: PingFang-SC-Medium;
}
.lookcp {
  margin-top: 0.3rem;
  font-size: 0.3rem;
  color: #989898;
}
.information {
  margin-top: 0.5rem;
  padding: 0 0.5rem 0 0.5rem;
}
.title {
  font-size: 0.45rem;
  font-family: PingFang-SC-Medium;
  color: #3d3d3d;
}
.mainpic {
  width: 0.6rem;
  height: 0.6rem;
}
.table {
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  height: 3rem;
}
.ziliao {
  width: 25%;
  margin: 0.2rem 0;
}
.ziliaoname {
  text-align: center;
  color: #989898;
  font-family: PingFang-SC-Medium;
  font-size: 0.3rem;
}
.lxtupian {
  text-align: center;
}
.hbsc {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-radius: 0 0 0.2rem 0.2rem;
  background-color: rgba(0, 0, 0, 0.5);
  height: 0.6rem;
  display: flex;
  align-items: center;
}
.ztyangshi {
  font-size: 0.3rem;
  color: rgba(255, 255, 255);
  margin-left: 0.4rem;
}
.seemainpic {
  width: 0.4rem;
  height: 0.4rem;
  margin-left: 0.1rem;
}
</style>
